@font-face {
font-family: '__Interface_97f6a1';
src: url(/_next/static/media/98ed29ff2bc362f9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: bold;
}

@font-face {
font-family: '__Interface_97f6a1';
src: url(/_next/static/media/3bce2c0df22c7261-s.p.woff2) format('woff2');
font-display: swap;
font-weight: normal;
}@font-face {font-family: '__Interface_Fallback_97f6a1';src: local("Arial");ascent-override: 98.23%;descent-override: 26.45%;line-gap-override: 0.00%;size-adjust: 91.56%
}.__className_97f6a1 {font-family: '__Interface_97f6a1', '__Interface_Fallback_97f6a1'
}.__variable_97f6a1 {--font-interface: '__Interface_97f6a1', '__Interface_Fallback_97f6a1'
}

